var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":"编辑","confirmLoading":_vm.confirmLoading,"destroyOnClose":true,"maskClosable":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleConfirm}},[_c('a-form',{attrs:{"form":_vm.dataForm,"label-col":{ span: 6 },"wrapper-col":{ span: 18 }}},[_c('a-form-item',{attrs:{"label":"包材型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'packing_code',
            {
              initialValue: _vm.item.packing_code,
              rules: [
                { required: true, message: '请输入包材型号' },
                { max: 256, message: '超出最大长度(256)' },
              ],
            },
          ]),expression:"[\n            'packing_code',\n            {\n              initialValue: item.packing_code,\n              rules: [\n                { required: true, message: '请输入包材型号' },\n                { max: 256, message: '超出最大长度(256)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"Country"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'country',
            {
              initialValue: _vm.item.country,
              rules: [
                { required: true, message: '请输入 Country' },
                { max: 256, message: '超出最大长度(256)' },
              ],
            },
          ]),expression:"[\n            'country',\n            {\n              initialValue: item.country,\n              rules: [\n                { required: true, message: '请输入 Country' },\n                { max: 256, message: '超出最大长度(256)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"最大申报价值"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'max_declaration_value',
            {
              initialValue: _vm.item.max_declaration_value,
              rules: [
                { required: true, message: '请输入最大申报价值' },
                { max: 256, message: '超出最大长度(256)' },
              ],
            },
          ]),expression:"[\n            'max_declaration_value',\n            {\n              initialValue: item.max_declaration_value,\n              rules: [\n                { required: true, message: '请输入最大申报价值' },\n                { max: 256, message: '超出最大长度(256)' },\n              ],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"分拣道口 A"}},[_c('CrossingSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target_crossing_a',
            { initialValue: _vm.item.target_crossing_a, rules: [{ required: true, message: '请选择分拣道口 A' }] },
          ]),expression:"[\n            'target_crossing_a',\n            { initialValue: item.target_crossing_a, rules: [{ required: true, message: '请选择分拣道口 A' }] },\n          ]"}],attrs:{"productionLine":"a"}})],1),_c('a-form-item',{attrs:{"label":"分拣道口 B"}},[_c('CrossingSelect',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'target_crossing_b',
            { initialValue: _vm.item.target_crossing_b, rules: [{ required: true, message: '请选择分拣道口 B' }] },
          ]),expression:"[\n            'target_crossing_b',\n            { initialValue: item.target_crossing_b, rules: [{ required: true, message: '请选择分拣道口 B' }] },\n          ]"}],attrs:{"productionLine":"b"}})],1),_c('a-form-item',{attrs:{"label":"最大重量(kg)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'max_weight',
            {
              initialValue: _vm.item.max_weight,
              rules: [{ required: true, message: '请输入最大重量' }],
            },
          ]),expression:"[\n            'max_weight',\n            {\n              initialValue: item.max_weight,\n              rules: [{ required: true, message: '请输入最大重量' }],\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"最大长度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'max_length',
            {
              initialValue: _vm.item.max_length,
            },
          ]),expression:"[\n            'max_length',\n            {\n              initialValue: item.max_length,\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"最大宽度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'max_width',
            {
              initialValue: _vm.item.max_width,
            },
          ]),expression:"[\n            'max_width',\n            {\n              initialValue: item.max_width,\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"最大高度(mm)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'max_height',
            {
              initialValue: _vm.item.max_height,
            },
          ]),expression:"[\n            'max_height',\n            {\n              initialValue: item.max_height,\n            },\n          ]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"非该包装方式"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'not_packing_method',
            {
              initialValue: _vm.item.not_packing_method,
              rules: [{ message: '请输入非该包装方式' }, { max: 256, message: '超出最大长度(256)' }],
            },
          ]),expression:"[\n            'not_packing_method',\n            {\n              initialValue: item.not_packing_method,\n              rules: [{ message: '请输入非该包装方式' }, { max: 256, message: '超出最大长度(256)' }],\n            },\n          ]"}],attrs:{"allowClear":true}})],1),_c('a-form-item',{attrs:{"label":"启用状态"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_enable', { initialValue: _vm.item.is_enable, valuePropName: 'checked' }]),expression:"['is_enable', { initialValue: item.is_enable, valuePropName: 'checked' }]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }